<script>
// Import necessary modules and components
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import HttpRequest from "@/http/HttpRequest";
import Utils from "@/helpers/Utils";
import Preference from "@/data/Preference";
import Constants from "@/data/Constants";
import Swal from "sweetalert2";
import { bus } from "@/views/pages/dashboard/index";
import Role from "@/data/Roles";
import Dropdown from '@/components/Dropdown';
// Create an instance of the Role class for role checking
let roleChecker = new Role();

// Create instances of Preference and HttpRequest classes
let preference = new Preference();
let httpRequest = new HttpRequest();

export default {
  props: ['queryId', 'queryName', 'queryDate'],
  // Initial data for the component
  data() {
    return {
      // Loading state for images
      isImageLoading: true,

      // Component title
      title: "Activities",

      // User role obtained from preferences
      role: preference.getData(Preference.KEY_ROLE_ID),

      // Breadcrumb items
      items: [
        {
          text: "WORKANY",
          to: "/",
        },
        {
          text: "Activities",
          active: true,
        },
      ],

      // Loading states for various data fetching processes
      isLoading: false,
      isEmpty: false,
      isLoadingMembers: false,

      // Selected files and associated data
      selectedFiles: [],
      selectedNames: [],
      selectedFileIndex: -1,

      // Lists for project and member selection
      projectListSelectOption: [],
      projectIdList: [],
      selectedProjectId: null,
      selectedProjectName: [],
      memberListSelectOption: [],
      selectedMemberId: null,
      selectedMemberName: [],
      selectedMemberNameInit: this.queryName ? this.queryName : preference.getData(Preference.KEY_NAME),


      // Date for activity selection
      date: null,

      // Activity data to be displayed
      activitiesData: [],

      // Flag indicating if the user is the owner of the selected project
      isOwnerOfTheSelectedProject: false,

      // Slider settings
      slide: 0,

      // Auto-refresh delay obtained from preferences
      delay: preference.getData(Preference.KEY_AUTO_REFRESH_TIME),

      // Auto-refresh runner (timer)
      runner: null,

      // Edited item placeholder
      editedItem: "",

      // Selected activity type for filtering
      selectedActivityType: 0,

      // Options for activity type dropdown
      options: [
        { text: "Task", value: 0 },
        { text: "Meetings", value: 1 },
        { text: "Others", value: 2 },
      ],

      // 'All' and 'Me' options for project and member selection
      all: {
        id: null,
        name: "All",
      },
      me: {
        id: null,
        name: "Me",
      },

      // Summary data for total, thinking time, and performance
      summaryData: [
        {
          title: "Total ",
          icon: "ri-time-line",
          value: "",
        },
        {
          title: "Thinking ",
          icon: "ri-time-line",
          value: "",
        },
        {
          title: "performance",
          icon: "ri-time-line",
          value: "",
        },
      ],
    };
  },

  // Registered components for the current Vue instance
  components: {
    Layout,
    PageHeader,
    Dropdown
  },

  methods: {
    validateSelection(selection) {
      if (selection) {
        this.selectedMemberId = selection.id;

      }
    },
    isRoleAccess(role_id, resource, action) {
      return roleChecker.checkAccess(role_id.toString(), resource, action);
    },
    // Function to fetch activities for the selected date, project, and member
    getActivities() {
      // Save reference to the current instance of the component
      let _this = this;

      // Format the date, get the timezone, and initialize isLoading flag
      let formattedDate = Utils.formatDate(_this.date);
      let timezone = Utils.getMyTimezone();
      _this.isLoading = true;
      // Make an HTTP request to get dashboard activities
      httpRequest
        .getDashboardActivities(
          formattedDate,
          formattedDate,
          timezone,
          _this.selectedProjectId,
          _this.selectedMemberId
        )
        .then(function (response) {
          // Remove the class 'some-class'


          // Set isLoading flag to false after receiving the response
          _this.isLoading = false;

          // Check if the request was successful and data is present
          if (
            response.success &&
            response.data.summary != null &&
            response.data.summary.total_time != null
          ) {
            // Set isEmpty flag to false as data is present
            _this.isEmpty = false;

            // Update summaryData with the received data
            _this.summaryData[0].value = Utils.secToTime(
              response.data.summary.total_time
            );
            _this.summaryData[1].value = Utils.secToTime(response.data.summary.idle_time);
            _this.summaryData[2].value = Math.ceil(
              ((response.data.summary.total_time - response.data.summary.idle_time) *
                100) /
              response.data.summary.total_time
            );

            // Initialize activitiesData array
            _this.activitiesData = [];

            // Initialize an array to store hour slot-wise data
            let hourSlotWiseData = [];

            // Iterate through date data in the response
            response.data.date_data.forEach(function (data) {
              if (data) {
                // Iterate through day data in the response
                data.day_data.forEach(function (data) {
                  // Process data for each hour slot
                  let totalTime = data.total_time;
                  if (totalTime > 600) {
                    totalTime = 600; // Limit total time to 600 seconds (10 minutes)
                  }

                  let idleTime = data.idle_time;
                  if (idleTime > 600) {
                    idleTime = 200; // Limit idle time to 200 seconds (3 minutes)
                  }

                  let startTime = data.start_time;
                  let endTime = data.end_time;
                  let activeTime = totalTime - idleTime;
                  let comment = data.comment;
                  let activityType = Utils.getActivityType(data.type);
                  let type = data.type;
                  let edited = data.edited;
                  let percent = Math.round((activeTime * 100) / totalTime);
                  if (percent > 99) {
                    percent = 96; // Limit percent to 96 if it exceeds 99
                  }

                  let totalMouseKeyboard =
                    parseInt(data.mouse_time) + parseInt(data.keyboard_time);
                  let mousePercent = Math.round(
                    (data.mouse_time * 100) / totalMouseKeyboard
                  );
                  let keyboardPercent = Math.round(
                    (data.keyboard_time * 100) / totalMouseKeyboard
                  );
                  // TODO: Optimize image loading with lazy loader instead of preload
                  let screenshots = [],
                    screenshotNames = [],
                    userName = [],
                    projectName = [],
                    thumbName = [];

                  // Process thumbnail names and screenshots
                  if (!Utils.isNull(data.thumb_names)) {
                    data.thumb_names.split(",").forEach((thumb) => {
                      // Fetch thumbnail images asynchronously
                      _this.getImage(thumb).then(function (response) {
                        _this.isImageLoading = false;
                        thumbName.push(response);
                      });
                    });
                  }

                  if (!Utils.isNull(data.screenshots)) {
                    data.screenshots.forEach(function (item) {
                      if (item.screenshot_url) {
                        item.screenshot_url.split(",").forEach(function (imageName) {
                          if (imageName) {
                            // Fetch screenshot images asynchronously
                            _this.getImage(imageName).then(function (response) {
                              screenshotNames.push(imageName);
                              screenshots.push(response);
                            });
                          }
                        });
                      }

                      if (item.user) {
                        // Collect user names associated with screenshots
                        item.user.forEach(function (name) {
                          userName.push(name);
                        });
                      }

                      if (item.project) {
                        // Collect project names associated with screenshots
                        item.project.forEach(function (name) {
                          projectName.push(name);
                        });
                      }
                    });
                  }

                  // Create activity data object
                  let activityData = {
                    activityPercent: percent,
                    mousePercent: mousePercent,
                    keyboardPercent: keyboardPercent,
                    hourSlot: Utils.getHourRange(startTime),
                    minuteRange: Utils.getMinuteRange(startTime, endTime),
                    hour: parseInt(Utils.getHour(startTime)),
                    minute: parseInt(Utils.getMinute(startTime)),
                    screenshots: screenshots,
                    thumb_name: thumbName,
                    screenshotNames: screenshotNames,
                    user: userName,
                    project: projectName,
                    comment: comment,
                    type: type,
                    activityType: activityType,
                    activityId: data.activity_id,
                    edited: edited,
                    totalTime: Utils.secToTime(totalTime),
                  };

                  // Push activity data to hourSlotWiseData array
                  hourSlotWiseData.push(activityData);
                });
              }
            });

            // Group hourSlotWiseData by hour slot
            let hourSlotWiseObjectData = Utils.groupByKey(hourSlotWiseData, "hourSlot");

            // Iterate through grouped data and create activitiesData array
            for (let key of Object.keys(hourSlotWiseObjectData)) {
              let hourSlotWiseData = hourSlotWiseObjectData[key];

              // Sort hourSlotWiseData by minute
              hourSlotWiseData.sort(_this.sortDateByMinute);

              // Create activity data object for each hour slot
              let activityData = {
                hourSlot: key,
                hour: hourSlotWiseData[0].hour,
                data: hourSlotWiseData,
              };

              // Push activity data to activitiesData array
              _this.activitiesData.push(activityData);
            }

            // Sort activitiesData by hour
            _this.activitiesData.sort(_this.sortDateByHour);
            let cardBody = document.querySelector('.card-body');
            cardBody.classList.remove('card-body-height');
          } else {
            // Set isEmpty flag to true if no data is present
            _this.isEmpty = true;
            _this.activitiesData = [];

          }

          // Set isImageLoading flag to true after processing data
          _this.isImageLoading = false;
        });
    },

    // Function to fetch the list of projects
    getProjectList() {
      // Save reference to the current instance of the component
      let _this = this;

      // Set isLoading flag to true while fetching the project list
      _this.isLoading = true;

      // Check the role access permission for reading projects
      let accessPermission = _this.isRoleAccess(this.role, "Project", "read");

      // Make an HTTP request to get the project list based on access permission
      return httpRequest.getProjectList(accessPermission).then(function (response) {
        // Set isLoading flag to false after receiving the response
        _this.isLoading = false;

        // Check if the request was successful
        if (response.success) {
          // Iterate through the received project data
          response.data.forEach(function (projectData) {
            // Check if the project status is active (project_status == 1)
            if (projectData.project.project_status == 1) {
              // Create a simplified project object for select options
              let project = {
                id: projectData.project.project_id,
                name: projectData.project.project_name,
              };

              // Add the project to the select options list
              _this.projectListSelectOption.push(project);

              // Check role access for reading projects and add to the projectIdList
              if (_this.isRoleAccess(projectData.role_id, "Project", "read")) {
                _this.projectIdList.push(projectData.project.project_id);
              }
            }
          });
        }
      });
    },

    // Function to fetch the list of members for the selected project
    getProjectMemberList() {
      // Save reference to the current instance of the component
      let _this = this;

      // Clear the member list select options
      _this.memberListSelectOption = [];

      // Check if a project is selected
      if (_this.selectedProjectId != null) {
        // Set isLoadingMembers flag to true while fetching project members
        _this.isLoadingMembers = true;

        // Make an HTTP request to get the members of the selected project
        httpRequest.getProjectMembers(_this.selectedProjectId).then(function (response) {
          // Set isLoadingMembers flag to false after receiving the response
          _this.isLoadingMembers = false;

          // Check if the request was successful
          if (response.success) {
            // Get the user ID of the current user from preferences
            let myUserId = preference.getData(Preference.KEY_USER_ID);

            // Flag to check if the current user is the owner of the selected project
            _this.isOwnerOfTheSelectedProject = false;

            // Iterate through the received member data
            response.data.forEach(function (member) {
              // Check if the member is the current user
              if (member.user.user_id === myUserId) {
                // Set selectedMemberId and get the role ID of the current user
                _this.selectedMemberId = member.user.user_id;
                let myRoleId = member.role_id;

                // Check if the current user is the owner of the selected project
                _this.isOwnerOfTheSelectedProject = _this.isRoleAccess(
                  myRoleId,
                  "Member",
                  "read"
                );
              }

              // Create a simplified user object for select options
              let user = {
                id: member.user.user_id,
                name: member.user.name,
              };

              // Add the user to the member list select options
              _this.memberListSelectOption.push(user);
            });

            // Check conditions to modify memberListSelectOption based on access rights
            if (
              _this.isOwnerOfTheSelectedProject == false &&
              _this.isRoleAccess(_this.role, "Member", "read") == false
            ) {
              // Reset memberListSelectOption and add the current user to it
              _this.memberListSelectOption = [];
              let user = {
                id: _this.selectedMemberId,
                name: preference.getData(Preference.KEY_NAME),
              };
              _this.memberListSelectOption.push(user);
            }
          }
        });
      } else {
        // If no project is selected, fetch all members based on the project list
        _this.getAllMembersBasedOnProjectList();
      }
    },

    // Function to fetch all members based on the project list
    getAllMembersBasedOnProjectList() {
      // Save reference to the current instance of the component
      let _this = this;

      // Initialize memberListSelectOption array
      _this.memberListSelectOption = [];

      // Check if there are projects in the projectIdList
      if (_this.projectIdList.length > 0) {
        // Set isLoadingMembers flag to true while fetching project members
        _this.isLoadingMembers = true;

        // Make an HTTP request to get the project members for the project list
        httpRequest.getProjectMembers(_this.projectIdList).then(function (response) {
          // Set isLoadingMembers flag to false after receiving the response
          _this.isLoadingMembers = false;

          // Check if the request was successful
          if (response.success) {
            // Get the user ID of the current user from preferences
            let myUserId = preference.getData(Preference.KEY_USER_ID);

            // Initialize isOwnerOfTheSelectedProject flag to false
            _this.isOwnerOfTheSelectedProject = false;

            // Iterate through the project members in the response
            response.data.forEach(function (member) {
              // Check if the member is not null
              if (member.user !== null) {
                // Check if the member is the current user
                let myRoleId = -1;
                if (member.user.user_id === myUserId) {
                  _this.selectedMemberId = member.user.user_id;
                  _this.selectedMemberNameInit = member.user.name
                  myRoleId = member.role_id;

                  // Check if the current user is the owner of the selected project
                  _this.isOwnerOfTheSelectedProject =
                    myRoleId === Constants.ROLE_PRODUCT_OWNER;
                }

                // Create a user object with id and name and add it to the memberListSelectOption
                let user = {
                  id: member.user.user_id,
                  name: member.user.name,
                };
                _this.memberListSelectOption.push(user);
              }
            });

            // Remove duplicates from memberListSelectOption based on user id
            if (_this.memberListSelectOption.length > 0) {
              const key = "id";
              _this.memberListSelectOption = [
                ...new Map(
                  _this.memberListSelectOption.map((item) => [item[key], item])
                ).values(),
              ];
            } else {
              // If memberListSelectOption is empty, add the current user as the only option
              _this.selectedMemberId = preference.getData(Preference.KEY_USER_ID);
              _this.me.id = _this.selectedMemberId;
              _this.me.name = preference.getData(Preference.KEY_NAME);
              _this.memberListSelectOption.push(this.me);
            }
          }
        });
      } else {
        // If no projects in the projectIdList, add the current user as the only option
        _this.selectedMemberId = preference.getData(Preference.KEY_USER_ID);
        _this.me.id = _this.selectedMemberId;
        _this.me.name = preference.getData(Preference.KEY_NAME);
        _this.memberListSelectOption.push(this.me);
      }
    },

    loadSelectedData() {
      let cardBody = document.querySelector('.card-body');
      cardBody.classList.remove('card-body-height');

      this.getActivities();
    },
    // Function to load initial data when the component is initialized
    loadInitialData() {
      // Set the date to today in the desired format
      // this.date = Utils.getToday().replaceAll("/", "-");
      this.date = this.queryDate ? this.queryDate : Utils.getToday().replaceAll("/", "-");


      // Add the 'All' option to the projectListSelectOption
      this.projectListSelectOption.push(this.all);

      // Check if selectedMemberId is null, if so, set it to the current user's ID
      if (this.selectedMemberId == null) {
        this.queryId ? this.selectedMemberId = this.queryId : this.selectedMemberId = preference.getData(Preference.KEY_USER_ID);
        this.me.id = this.selectedMemberId;
        this.me.name = this.queryName ? this.queryName : preference.getData(Preference.KEY_NAME);
      }

      // Add the current user to the memberListSelectOption
      this.memberListSelectOption.push(this.me);

      // Fetch activities for the component
      this.getActivities();
    },

    sortDateByHour(a, b) {
      if (a.hour < b.hour) {
        return 1;
      }
      if (a.hour > b.hour) {
        return -1;
      }
      return 0;
    },
    sortDateByMinute(a, b) {
      if (a.minute < b.minute) {
        return -1;
      }
      if (a.minute > b.minute) {
        return 1;
      }
      return 0;
    },
    getTooltipText(totalTime, mouse, keyboard, images) {
      return (
        "Total Time: " +
        totalTime +
        "\n Mouse: " +
        mouse +
        "%, Keyboard: " +
        keyboard +
        "%,\n Total Screenshots: " +
        images
      );
    },
    getEditCommentTooltip(type, comment) {
      if (comment) {
        return type + " | " + comment;
      }
    },
    invokeModal(modalId, selectedNames, selectedFiles, selectedUser, selectedProject) {
      this.selectedNames = selectedNames;
      this.selectedFiles = selectedFiles;
      this.selectedMemberName = selectedUser;
      this.selectedProjectName = selectedProject;
      this.slide = 0;

      if (this.selectedFiles.length > 0) {
        this.$bvModal.show(modalId);
      } else {
        this.$bvToast.toast(
          "Either removed or you don't have enough permission to see this!",
          {
            title: "No screenshot!",
            variant: "warning",
            solid: true,
          }
        );
      }
    },
    selectFileIndex(index) {
      this.selectedFileIndex = index;
    },
    removeImage(modalId) {
      let _this = this;
      Swal.fire({
        title: "Are you sure?",
        text: "You want to remove this image?",
        showCancelButton: true,
        icon: "warning",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          let imageName = _this.selectedNames[this.slide];
          httpRequest.deleteFile(imageName).then(function (response) {
            if (response.success) {
              _this.$bvModal.hide(modalId);
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
            } else {
              Swal.fire(
                "Failed!",
                "Either you don't have authorization to remove this file or something went wrong!",
                "error"
              );
            }
            _this.loadSelectedData();
          });
        }
      });
    },
    async getImage(image) {
      //return Constants.AWS_FILE_BASE_URL + image
      await httpRequest.generateImageUrl(image).then(function (response) {
        if (response.success) {
          image = response.data.URL;
        }
      });
      return image;
    },
    onSlideStart(slide) {
      this.slide = slide;
    },
    onSlideEnd(slide) {
      this.slide = slide;
    },
    setEditedActivity(item) {
      this.editedItem = item;
      this.selectedActivityType = item.type;
    },
    updateActivityData(modalId) {
      let _this = this;
      let activityId = this.editedItem.activityId;
      let comment = this.editedItem.comment;
      let type = this.selectedActivityType;
      if (comment) {
        httpRequest.editActivityData(activityId, comment, type).then(function (response) {
          if (response.success) {
            _this.$bvModal.hide(modalId);
            Swal.fire("Updated!", "Activity data updated successfully", "success").then(
              (result) => {
                if (result) {
                  _this.getActivities();
                }
              }
            );
          } else {
            _this.$bvModal.hide(modalId);
            Swal.fire(
              "Failed!",
              "Either you don't have authorization to edit this activity or something went wrong!",
              "error"
            );
          }
        });
      } else {
        Swal.fire("Failed!", "Please add a comment also", "error");
      }
    },
  },
  // Asynchronous created lifecycle hook
  async created() {


    // Set up event listener for auto refresh value changes
    bus.$on("auto_refresh_val", (data) => {
      // Update delay value and clear the existing interval
      this.delay = data;
      clearInterval(this.runner);

      // Set up a new interval if delay is greater than 0
      if (this.delay > 0) {
        this.runner = setInterval(this.loadSelectedData, this.delay * 60 * 1000);
      }
    });

    // Fetch project list asynchronously
    await this.getProjectList();

    // Load initial data asynchronously
    await this.loadInitialData();

    // Fetch all members based on project list asynchronously
    await this.getAllMembersBasedOnProjectList();

    // Set up auto refresh based on the previously set value
    clearInterval(this.runner);
    if (this.delay > 0) {
      this.runner = setInterval(this.loadSelectedData, this.delay * 60 * 1000);
    }
  },

  mounted() {
    // Access the user_id parameter from the URL

    // Now you can use userId in your component logic
    console.log("this is user id 1", this.queryName);
    console.log("this is user id 2", this.queryId);
    console.log("this is user id 2", this.queryDate);

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col mb-4">
        <div class="card">
          <div class="card-body card-body-height">
            <div class="row">
              <div class="col-sm-6 col-md col-xl-4">
                <label>Date</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                          <i class="ri-calendar-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>
                      <b-form-datepicker id="from-datepicker" class="date_pick" v-model="date" :date-format-options="{
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      weekday: 'short',
    }" placeholder="Today"></b-form-datepicker>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-sm-6 col-md-3">
                <label>Project</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                          <i class="ri-folder-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <b-form-select :options="projectListSelectOption" v-model="selectedProjectId" value-field="id"
                        text-field="name" v-on:change="getProjectMemberList"></b-form-select>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-sm-6 col-md-3">
                <label>Member</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                          <i class="ri-user-3-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>
                      <!-- 
                      <b-form-select :options="memberListSelectOption" v-model="selectedMemberId" value-field="id"
                        text-field="name" :disabled="isLoadingMembers"></b-form-select> -->

                      <Dropdown :options="memberListSelectOption" :maxItem="200" v-on:selected="validateSelection"
                        :initialData="selectedMemberNameInit">

                      </Dropdown>
                    </b-input-group>
                  </div>
                </template>



              </div>
              <div class="col-sm-6 col-md-auto col-xl-2">
                <label>Action</label>
                <b-button block class="customs-bg-color" v-on:click="loadSelectedData"><i class="ri-eye-line"
                    style="vertical-align: bottom"></i> VIEW
                </b-button>
              </div>
            </div>
            <div class="alert alert-info text-center" style="
                background: #fff;
                border: none;
                margin-top: 20px;
                position: relative;
                padding: 0;
              " role="alert" v-if="!isLoading && !isEmpty">
              <div class="alert alert-info- mt-3- pb-1 d-flex justify-content-between align-items-center"
                style="border: 1px solid #ced4da">
                <div v-for="(data, index) in summaryData" :key="index">
                  <h6 class="customs-text-color font-weight-bold text-uppercase">
                    {{ data.title }} : {{ data.value
                    }}<span v-if="data.title == 'performance'">%</span>
                  </h6>
                </div>
              </div>
            </div>
            <div class="row mt-2" v-if="!isLoading && !isEmpty">
              <div class="col-12">
                <div data-simplebar>
                  <ul class="list-unstyled activity-wid">
                    <li class="activity-list" v-for="(data, parentIndex) in activitiesData" :key="parentIndex">
                      <div class="activity-icon avatar-xs">
                        <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                          <i class="ri-checkbox-blank-circle-fill"></i>
                        </span>
                      </div>
                      <div>
                        <div>
                          <h5>
                            {{ data.hourSlot }}
                          </h5>
                          <span>
                            {{ data.hourSlot }}
                          </span>
                        </div>

                        <div class="row mt-2">
                          <div class="col-6 col-sm-4 col-xl-2 mt-3" v-for="(data, childIndex) in data.data"
                            :key="childIndex">
                            <div style="background: #eeeeee; cursor: pointer" v-on:click="
      invokeModal(
        'img-view',
        data.screenshotNames,
        data.screenshots,
        data.user,
        data.project
      )
      " class="card mb-4" v-b-tooltip.hover :title="getEditCommentTooltip(data.activityType, data.comment)
      ">
                              <div class="row" v-if="isImageLoading">
                                <div class="col-12" style="min-height: 118px">
                                  <div class="card h-100">
                                    <div class="card-body">
                                      <div class="text-center mt-4 mb-4">
                                        <b-spinner class="align-middle"></b-spinner>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div v-if="!isImageLoading">
                                <img class="card-img" :src="data.thumb_name[0]
      ? data.thumb_name[0]
      : data.screenshots[0]
      " height="90px" width="100%" style="object-fit: cover" />
                                <div class="card-img-overlay d-flex">
                                  <span v-if="data.edited" :class="[
      'badge badge-pill align-self-center mx-auto',
      'badge-info',
    ]">
                                    In a meeting</span>

                                  <span v-else :class="[
      'badge badge-pill align-self-center mx-auto',
      data.activityPercent <= 30
        ? 'badge-danger'
        : 'badge-primary',
      data.activityPercent <= 60
        ? 'badge-warning'
        : 'badge-primary',
      data.activityPercent > 60
        ? 'badge-success'
        : 'badge-primary',
    ]">
                                    {{ data.activityPercent }}%</span>
                                </div>
                              </div>
                            </div>
                            <h6 class="font-size-13">{{ data.minuteRange }}</h6>
                            <div class="row mt-2">
                              <div class="col pr-0">
                                <b-progress v-b-tooltip.hover :title="getTooltipText(
      data.totalTime,
      data.mousePercent,
      data.keyboardPercent,
      data.screenshots.length
    )
      " height="5px" :value="data.activityPercent" class="mt-3"></b-progress>
                              </div>
                              <div class="col-auto pl-0">
                                <i class="ri-chat-new-line" style="font-size: 22px; color: #4b1fe5"></i>
                                <!-- v-b-modal.EditActivityModal -->
                                <!-- @click="setEditedActivity(data)" -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="row mt-5 d-flex justify-content-center" v-if="isLoading">
              <b-spinner class="align-middle"></b-spinner>
            </div>

            <div class="alert alert-info mt-3 d-flex justify-content-center" v-if="isEmpty">
              No data to show!
            </div>
          </div>
        </div>
      </div>

      <b-modal id="img-view" size="xl" centered :title="selectedMemberName[slide] + ' | ' + selectedProjectName[slide]"
        title-class="font-18">
        <b-carousel id="carousel-1" v-model="slide" :interval="600000" img-width="100%" controls indicators
          background="#ababab" style="text-shadow: 1px 1px 2px #333" @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd">
          <b-carousel-slide v-for="(data, index) in selectedFiles" :key="index" :img-src="data" alt="image slot"
            style="min-height: 500px">
            {{ selectFileIndex(index) }}
          </b-carousel-slide>
        </b-carousel>

        <template #modal-footer>
          <h5></h5>
          <b-button v-if="isOwnerOfTheSelectedProject" size="sm" variant="outline-danger" :disabled="role != 3"
            @click="removeImage('img-view')">
            REMOVE IMAGE
          </b-button>
        </template>
      </b-modal>

      <b-modal id="EditActivityModal" centered :title="'Edit Activity (' + editedItem.minuteRange + ')'" hide-footer>
        <form class="form-horizontal" @submit.prevent="">
          <div class="form-group auth-form-group-custom mb-4">
            <i class="ri-file-edit-line auti-custom-input-icon"></i>
            <label for="task_title">Comment</label>
            <input type="text" class="form-control" id="task_title" placeholder="Enter comment"
              v-model="editedItem.comment" />
          </div>
          <div class="form-group mb-4">
            <b-form-group label="Activity Type" v-slot="{ ariaDescribedby }">
              <b-form-radio-group id="radio-group-1" v-model="selectedActivityType" :options="options"
                :aria-describedby="ariaDescribedby" name="radio-options"></b-form-radio-group>
            </b-form-group>
          </div>
          <div class="mt-4 text-center">
            <b-button variant="primary" size="" class="customs-bg-color" type="submit"
              v-on:click="updateActivityData('EditActivityModal')">UPDATE <i class="ri-arrow-right-s-line"
                style="vertical-align: bottom"></i>
            </b-button>
          </div>
        </form>
      </b-modal>
    </div>
  </Layout>
</template>

<style>
.customs-bg-color {
  background-color: #ff7c47 !important;
  border: none;
}

.customs-text-color {
  color: #ff7c47;
}

#from-datepicker>svg {
  display: none;
}
</style>

<style>
.dropdown-width .dropdown-content {
  min-width: 338px;
}

.card-body-height {
  height: 80vh !important;
}
</style>>
